<template>
  <div class="orgManage">
    <div class="page-title">组织管理</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>模糊搜索</div>
      <div class="w300 mL10">
        <el-input placeholder="请输入组织名称" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="row-me row-center mT20">
      <div class="btn-blue w150 btn-height40" @click="clickAdd">新建组织</div>
    </div>
    <div class="column-me flex1 overflow-auto mT20">
      <div class="flex1 overflow-auto">
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style" height="100%">
          <el-table-column type="index" label="序号" width="100" align="center"/>
          <el-table-column prop="deptId" label="ID" align="center" show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column prop="deptName" label="组织名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="parentName" label="上级组织" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="leader" label="负责人" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="phone" label="联系电话" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="250" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <div class="blue-text mL20" @click="clickAddChild(scope.row)">
                  添加下级组织
                </div>
                <div class="blue-text mL20" @click="clickEdit(scope.row)">编辑</div>
                <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                  <template #reference>
                    <div class="red-text mL20">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="title" v-model="visibleAdd" width="600px" top="10vh" :destroy-on-close="true">
      <div>
        <div class="row-me center-all mT30">
          <div class="w120 align-right">上级组织：</div>
          <div class="w350">
            <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="detailItem.parentId">
              <el-option v-for="item in orgArray" :key="item.deptId" :label="item.deptName" :value="item.deptId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-me center-all mT20">
          <div class="w120 align-right"><span class="red-star">*</span>组织名称：</div>
          <div class="w350">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.deptName"></el-input>
          </div>
        </div>
        <div class="row-me center-all mT20">
          <div class="w120 align-right">负责人：</div>
          <div class="w350">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.leader"></el-input>
          </div>
        </div>
        <div class="row-me center-all mT20">
          <div class="w120 align-right">联系电话：</div>
          <div class="w350">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.phone"
                      @input="detailItem.phone=$method.checkInput(detailItem.phone,0)"></el-input>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleAdd=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickSave">确定</div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: '',
      tableData: [],
      pageNo: 1,
      total: 0,
      title: '新建组织',
      visibleAdd: false,
      detailItem: {},
      orgArray: [],
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.clickSearch()
    },
    clickAdd() {
      this.getOrgArray()
      this.title = '新建组织'
      this.detailItem = {}
      this.visibleAdd = true
    },
    clickEdit(item) {
      this.getOrgArray()
      this.title = '编辑组织'
      this.detailItem = this.$method.deepClone(item)
      this.visibleAdd = true
    },
    clickAddChild(item) {
      this.getOrgArray()
      this.title = '添加下级组织'
      this.detailItem = {
        parentId: item.deptId,
      }
      this.visibleAdd = true
    },
    clickDelete(item) {
      this.$delete('/system/dept/' + item.deptId, {}).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickSave() {
      if (this.$method.isEmpty(this.detailItem.deptName)) {
        this.$message.error("请输入组织名称")
        return
      }
      if (this.detailItem.deptId) {
        this.$put('/system/dept', this.detailItem).then((res) => {
          if (res) {
            this.$message.success('保存成功')
            this.visibleAdd = false
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.detailItem.orderNum = 1
        this.$post('/system/dept', this.detailItem).then((res) => {
          if (res) {
            this.$message.success('保存成功')
            this.visibleAdd = false
            this.clickSearch()
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    getData() {
      this.$get('/system/dept/listPage', {
        deptName: this.name,
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取组织下拉
    getOrgArray() {
      this.$get('/system/dept/list', {}).then((res) => {
        if (res) {
          this.orgArray = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.orgManage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
